import { Fragment } from 'react'
import { Disclosure, Menu, Transition } from '@headlessui/react'
import { Bars3Icon, BellIcon, XMarkIcon } from '@heroicons/react/24/outline'
import logo from "../media/logo.png";

const user = {
  name: 'Tom Cook',
  email: 'tom@example.com',
  imageUrl:
    'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
}
const navigation = [
  { name: 'Admin', href: '/', current: true },
  { name: 'Stats', href: '/stats', current: false },
  { name: 'Úkoly', href: '/ukoly', current: false },
  { name: 'Hledat lidi', href: '/hledat-lidi', current: false },
  { name: 'QR kódy', href: '/qr-kody', current: false },
]
const userNavigation = [
  { name: 'Your Profile', href: '#' },
  { name: 'Settings', href: '#' },
  { name: 'Tutorial', href: '/tutorial' },
  { name: 'Leaderboard', href: '#' },
  { name: 'Sign out', href: '#' },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Home() {
  return (
    <>
      {/*
        This example requires updating your template:

        ```
        <html class="h-full bg-gray-100">
        <body class="h-full">
        ```
      */}
      <div className="min-h-full">
        <Disclosure as="nav" className="bg-gray-800 bg-peknaCerna text-bila">
          {({ open }) => (
            <>
              <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                <div className="flex h-16 items-center justify-between">
                  <div className="flex items-center">
                    <div className="flex-shrink-0">
                      <img
                        className="h-10 w-13"
                        src={logo}
                        alt="Your Company"
                      />
                    </div>
                    <div className="hidden md:block">
                      <div className="ml-10 flex items-baseline space-x-4">
                        {navigation.map((item) => (
                          <a
                            key={item.name}
                            href={item.href}
                            className={classNames(
                              item.current
                                ? 'bg-gray-900 text-white'
                                : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                              'rounded-md px-3 py-2 text-sm font-medium'
                            )}
                            aria-current={item.current ? 'page' : undefined}
                          >
                            {item.name}
                          </a>
                        ))}
                      </div>
                    </div>
                  </div>
                  <div className="hidden md:block">
                    <div className="ml-4 flex items-center md:ml-6">
                     {/*  <button
                        type="button"
                        className="relative rounded-full bg-gray-800 p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                      >
                        <span className="absolute -inset-1.5" />
                        <span className="sr-only">View notifications</span>
                        <BellIcon className="h-6 w-6" aria-hidden="true" />
                      </button> */}

                      {/* Profile dropdown */}
                      <Menu as="div" className="relative ml-3">
                        <div>
                          <Menu.Button className="relative flex max-w-xs items-center rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                            <span className="absolute -inset-1.5" />
                            <span className="sr-only">Open user menu</span>
                            <img className="h-8 w-8 rounded-full" src={user.imageUrl} alt="" />
                          </Menu.Button>
                        </div>
                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items className="absolute bg-peknaCerna right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                            {userNavigation.map((item) => (
                              <Menu.Item key={item.name}>
                                {({ active }) => (
                                  <a
                                    href={item.href}
                                    className={classNames(
                                      active ? 'bg-zluta text-cerna' : '',
                                      'block px-4 py-2 text-sm text-gray-700'
                                    )}
                                  >
                                    {item.name}
                                  </a>
                                )}
                              </Menu.Item>
                            ))}
                          </Menu.Items>
                        </Transition>
                      </Menu>
                    </div>
                  </div>
                  <div className="-mr-2 flex md:hidden">
                    {/* Mobile menu button */}
                    <Disclosure.Button className="relative inline-flex items-center justify-center rounded-md bg-gray-800 p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                      <span className="absolute -inset-0.5" />
                      <span className="sr-only">Open main menu</span>
                      {open ? (
                        <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                      ) : (
                        <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                      )}
                    </Disclosure.Button>
                  </div>
                </div>
              </div>

              <Disclosure.Panel className="md:hidden">
                <div className="space-y-1 px-2 pb-3 pt-2 sm:px-3">
                  {navigation.map((item) => (
                    <Disclosure.Button
                      key={item.name}
                      as="a"
                      href={item.href}
                      className={classNames(
                        item.current ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                        'block rounded-md px-3 py-2 text-base font-medium'
                      )}
                      aria-current={item.current ? 'page' : undefined}
                    >
                      {item.name}
                    </Disclosure.Button>
                  ))}
                </div>
                <div className="border-t border-gray-700 pb-3 pt-4">
                  <div className="flex items-center px-5">
                    <div className="flex-shrink-0">
                      <img className="h-10 w-10 rounded-full" src={user.imageUrl} alt="" />
                    </div>
                    <div className="ml-3">
                      <div className="text-base font-medium leading-none text-white">{user.name}</div>
                      <div className="text-sm font-medium leading-none text-gray-400">{user.email}</div>
                    </div>
                    <button
                      type="button"
                      className="relative ml-auto flex-shrink-0 rounded-full bg-gray-800 p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                    >
                      <span className="absolute -inset-1.5" />
                      <span className="sr-only">View notifications</span>
                      <BellIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                  <div className="mt-3 space-y-1 px-2">
                    {userNavigation.map((item) => (
                      <Disclosure.Button
                        key={item.name}
                        as="a"
                        href={item.href}
                        className="block rounded-md px-3 py-2 text-base font-medium text-gray-400 hover:bg-gray-700 hover:text-white"
                      >
                        {item.name}
                      </Disclosure.Button>
                    ))}
                  </div>
                </div>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>

        <header className="bg-zluta shadow">
          <div className="mx-auto max-w-7xl px-4 py-6 sm:px-6 lg:px-8">
            <h1 className="text-3xl font-bold tracking-tight text-gray-900">Tutorial</h1>
          </div>
        </header>
        <main>


          <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
            <div className="max-w-xl sm:mx-auto lg:max-w-2xl">
              <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
                <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto">
                  <span className="relative inline-block">
                    <svg
                      viewBox="0 0 52 24"
                      fill="currentColor"
                      className="absolute top-0 left-0 z-0 hidden w-32 -mt-8 -ml-20 text-blue-gray-100 lg:w-32 lg:-ml-28 lg:-mt-10 sm:block"
                    >
                      <defs>
                        <pattern
                          id="70326c9b-4a0f-429b-9c76-792941e326d5"
                          x="0"
                          y="0"
                          width=".135"
                          height=".30"
                        >
                          <circle cx="1" cy="1" r=".7" />
                        </pattern>
                      </defs>
                      <rect
                        fill="url(#70326c9b-4a0f-429b-9c76-792941e326d5)"
                        width="52"
                        height="24"
                      />
                    </svg>
                    <span className="relative">Tu</span>
                  </span>{' '}
                  jsou odpovědi na vše, co potřebujete vědět
                </h2>
                <p className="text-base text-gray-700 md:text-lg">
                (doufám)
                </p>
              </div>
            </div>
            <div className="max-w-screen-xl sm:mx-auto">
              <div className="grid grid-cols-1 gap-16 row-gap-8 lg:grid-cols-2">
                <div className="space-y-8">
                  <div>
                    <p className="mb-4 text-xl font-medium">
                      Co je na jednotlivých podstránkách?s
                    </p>
                    <p className="text-gray-700">
                      <b>Admin: </b> Overall stats registrovaných uživatelů.
                      <br />
                      <br />
                      Many say exploration is part of our destiny, but it’s actually
                      our duty to future generations.
                    </p>
                  </div>
                  <div>
                    <p className="mb-4 text-xl font-medium">
                      The first mate and his Skipper too will do?
                    </p>
                    <p className="text-gray-700">
                      Well, the way they make shows is, they make one show. That
                      show's called a pilot.
                      <br />
                      <br />
                      Then they show that show to the people who make shows, and on
                      the strength of that one show they decide if they're going to
                      make more shows. Some pilots get picked and become television
                      programs.Some don't, become nothing. She starred in one of the
                      ones that became nothing.
                    </p>
                  </div>
                  <div>
                    <p className="mb-4 text-xl font-medium">
                      Is the Space Pope reptilian!?
                    </p>
                    <p className="text-gray-700">
                      A flower in my garden, a mystery in my panties. Heart attack
                      never stopped old Big Bear. I didn't even know we were calling
                      him Big Bear.
                    </p>
                  </div>
                </div>
                <div className="space-y-8">
                  <div>
                    <p className="mb-4 text-xl font-medium">
                      How much money you got on you?
                    </p>
                    <p className="text-gray-700">
                      The first mate and his Skipper too will do their very best to
                      make the others comfortable in their tropic island nest.
                      <br />
                      <br />
                      Michael Knight a young loner on a crusade to champion the cause
                      of the innocent. The helpless. The powerless in a world of
                      criminals who operate above the law. Here he comes Here comes
                      Speed Racer. He's a demon on wheels.
                    </p>
                  </div>
                  <div>
                    <p className="mb-4 text-xl font-medium">
                      Galaxies Orion's sword globular star cluster?
                    </p>
                    <p className="text-gray-700">
                      A business big enough that it could be listed on the NASDAQ goes
                      belly up. Disappears!
                      <br />
                      <br />
                      It ceases to exist without me. No, you clearly don't know who
                      you're talking to, so let me clue you in.
                    </p>
                  </div>
                  <div>
                    <p className="mb-4 text-xl font-medium">
                      When has justice ever been as simple as a rule book?
                    </p>
                    <p className="text-gray-700">
                      This is not about revenge. This is about justice. A lot of
                      things can change in twelve years, Admiral. Well, that's
                      certainly good to know. About four years. I got tired of hearing
                      how young I looked.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  )
}
