import { Routes, Route } from "react-router-dom";
import Home from './pages/Home';
import Stats from './pages/Stats';
import Ukoly from './pages/Ukoly';
import HledatLidi from './pages/HledatLidi';
import QrKody from './pages/QrKody';
import Tutorial from './pages/Tutorial';

function App() {
    return (
      <div className="App">
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/stats' element={<Stats />} />
          <Route path='/ukoly' element={<Ukoly />} />
          <Route path='/hledat-lidi' element={<HledatLidi />} />
          <Route path='/qr-kody' element={<QrKody />} />
          <Route path='/tutorial' element={<Tutorial />} />
        </Routes>
      </div>
    );
  }
  
  export default App;