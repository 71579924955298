import { Fragment, useEffect } from "react";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { Bars3Icon, BellIcon, XMarkIcon } from "@heroicons/react/24/outline";
import logo from "../media/logo.png";
import { useState } from "react";
import { QrScanner } from '@yudiel/react-qr-scanner';
import { Api } from "../components/Knihovna";



const user = {
    name: "Tom Cook",
    email: "tom@example.com",
    imageUrl:
        "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
};
const navigation = [
    { name: "Admin", href: "/", current: false },
    { name: "Stats", href: "/stats", current: false },
    { name: "Úkoly", href: "/ukoly", current: true },
    { name: "Hledat lidi", href: "/hledat-lidi", current: false },
    { name: "QR kódy", href: "/qr-kody", current: false },
];
const userNavigation = [
    { name: "Your Profile", href: "#" },
    { name: "Settings", href: "#" },
    { name: "Tutorial", href: "/tutorial" },
    { name: 'Leaderboard', href: '#' },
    { name: "Sign out", href: "#" },
];

function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}

export default function Home() {
    
    const [section, setSection] = useState(1);
    const [cass, setCas] = useState("");
    const [ulohaa, setUloha] = useState("");
    const [usrIdd, setUsrId] = useState("");
    
    function akce(result) {
        setSection(2)
        setUsrId(result)
    }

    const submitScore = () => {
        Api.addScore(usrIdd, cass, ulohaa);
        setCas("");
        console.log("skóre přidáno");
    }
    function handleSelectChange(selectedOptionId, cas) {
        switch (selectedOptionId) {
            case "1":
                break;
            case "2":
                 //fall guys
                setUloha(1);
                break;
            case "3":
                 //tetris
                setUloha(0);
                break;
            case "4":
                 //matyho hra;
                setUloha(2);
                break;
            case "5":
                 //unikovka
                setUloha(5);
                break;
            case "6":
                //karlova hra
                setUloha(6);
                break;
            case "7":
                //volné zadávání bodů
                setUloha(7);
                break;
            default:
                console.log("default");
        }
    }

    const [showToast, setShowToast] = useState(false);

    const handleShowToast = () => {
        setShowToast(true);

        // Hide the toast after 3 seconds
        setTimeout(() => {
            setShowToast(false);
        }, 3000);
    };

    function Toast({ message, showToast, onClose }) {
        const [visible, setVisible] = useState(false);

        useEffect(() => {
            if (showToast) {
                setVisible(true);

                // Auto-hide the toast after a few seconds (adjust as needed)
                const timeout = setTimeout(() => {
                    onClose();
                }, 3000);

                return () => clearTimeout(timeout);
            }
        }, [showToast, onClose]);

        return (
            <div
                className={`fixed top-5 right-5 bg-zelena bg-opacity-70 p-3 bg-blue-500 text-bila rounded-md ${
                    visible
                        ? "opacity-100 translate-y-0"
                        : "opacity-0 translate-y-full"
                } transform transition-transform transition-opacity ease-in-out duration-300`}
            >
                {message}
            </div>
        );
    }

    return (
        <>
            <div className="min-h-full">
                <Disclosure
                    as="nav"
                    className="bg-gray-800 bg-peknaCerna text-bila"
                >
                    {({ open }) => (
                        <>
                            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                                <div className="flex h-16 items-center justify-between">
                                    <div className="flex items-center">
                                        <div className="flex-shrink-0">
                                            <img
                                                className="h-10 w-13"
                                                src={logo}
                                                alt="Your Company"
                                            />
                                        </div>
                                        <div className="hidden md:block">
                                            <div className="ml-10 flex items-baseline space-x-4">
                                                {navigation.map((item) => (
                                                    <a
                                                        key={item.name}
                                                        href={item.href}
                                                        className={classNames(
                                                            item.current
                                                                ? "bg-gray-900 text-white"
                                                                : "text-gray-300 hover:bg-gray-700 hover:text-white",
                                                            "rounded-md px-3 py-2 text-sm font-medium"
                                                        )}
                                                        aria-current={
                                                            item.current
                                                                ? "page"
                                                                : undefined
                                                        }
                                                    >
                                                        {item.name}
                                                    </a>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="hidden md:block">
                                        <div className="ml-4 flex items-center md:ml-6">
                                            {/*  <button
                        type="button"
                        className="relative rounded-full bg-gray-800 p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                      >
                        <span className="absolute -inset-1.5" />
                        <span className="sr-only">View notifications</span>
                        <BellIcon className="h-6 w-6" aria-hidden="true" />
                      </button> */}

                                            {/* Profile dropdown */}
                                            <Menu
                                                as="div"
                                                className="relative ml-3"
                                            >
                                                <div>
                                                    <Menu.Button className="relative flex max-w-xs items-center rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                                                        <span className="absolute -inset-1.5" />
                                                        <span className="sr-only">
                                                            Open user menu
                                                        </span>
                                                        <img
                                                            className="h-8 w-8 rounded-full"
                                                            src={user.imageUrl}
                                                            alt=""
                                                        />
                                                    </Menu.Button>
                                                </div>
                                                <Transition
                                                    as={Fragment}
                                                    enter="transition ease-out duration-100"
                                                    enterFrom="transform opacity-0 scale-95"
                                                    enterTo="transform opacity-100 scale-100"
                                                    leave="transition ease-in duration-75"
                                                    leaveFrom="transform opacity-100 scale-100"
                                                    leaveTo="transform opacity-0 scale-95"
                                                >
                                                    <Menu.Items className="absolute bg-peknaCerna right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                        {userNavigation.map(
                                                            (item) => (
                                                                <Menu.Item
                                                                    key={
                                                                        item.name
                                                                    }
                                                                >
                                                                    {({
                                                                        active,
                                                                    }) => (
                                                                        <a
                                                                            href={
                                                                                item.href
                                                                            }
                                                                            className={classNames(
                                                                                active
                                                                                    ? "bg-zluta text-cerna"
                                                                                    : "",
                                                                                "block px-4 py-2 text-sm text-gray-700"
                                                                            )}
                                                                        >
                                                                            {
                                                                                item.name
                                                                            }
                                                                        </a>
                                                                    )}
                                                                </Menu.Item>
                                                            )
                                                        )}
                                                    </Menu.Items>
                                                </Transition>
                                            </Menu>
                                        </div>
                                    </div>
                                    <div className="-mr-2 flex md:hidden">
                                        {/* Mobile menu button */}
                                        <Disclosure.Button className="relative inline-flex items-center justify-center rounded-md bg-gray-800 p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                                            <span className="absolute -inset-0.5" />
                                            <span className="sr-only">
                                                Open main menu
                                            </span>
                                            {open ? (
                                                <XMarkIcon
                                                    className="block h-6 w-6"
                                                    aria-hidden="true"
                                                />
                                            ) : (
                                                <Bars3Icon
                                                    className="block h-6 w-6"
                                                    aria-hidden="true"
                                                />
                                            )}
                                        </Disclosure.Button>
                                    </div>
                                </div>
                            </div>

                            <Disclosure.Panel className="md:hidden">
                                <div className="space-y-1 px-2 pb-3 pt-2 sm:px-3">
                                    {navigation.map((item) => (
                                        <Disclosure.Button
                                            key={item.name}
                                            as="a"
                                            href={item.href}
                                            className={classNames(
                                                item.current
                                                    ? "bg-gray-900 text-white"
                                                    : "text-gray-300 hover:bg-gray-700 hover:text-white",
                                                "block rounded-md px-3 py-2 text-base font-medium"
                                            )}
                                            aria-current={
                                                item.current
                                                    ? "page"
                                                    : undefined
                                            }
                                        >
                                            {item.name}
                                        </Disclosure.Button>
                                    ))}
                                </div>
                                <div className="border-t border-gray-700 pb-3 pt-4">
                                    <div className="flex items-center px-5">
                                        <div className="flex-shrink-0">
                                            <img
                                                className="h-10 w-10 rounded-full"
                                                src={user.imageUrl}
                                                alt=""
                                            />
                                        </div>
                                        <div className="ml-3">
                                            <div className="text-base font-medium leading-none text-white">
                                                {user.name}
                                            </div>
                                            <div className="text-sm font-medium leading-none text-gray-400">
                                                {user.email}
                                            </div>
                                        </div>
                                        <button
                                            type="button"
                                            className="relative ml-auto flex-shrink-0 rounded-full bg-gray-800 p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                                        >
                                            <span className="absolute -inset-1.5" />
                                            <span className="sr-only">
                                                View notifications
                                            </span>
                                            <BellIcon
                                                className="h-6 w-6"
                                                aria-hidden="true"
                                            />
                                        </button>
                                    </div>
                                    <div className="mt-3 space-y-1 px-2">
                                        {userNavigation.map((item) => (
                                            <Disclosure.Button
                                                key={item.name}
                                                as="a"
                                                href={item.href}
                                                className="block rounded-md px-3 py-2 text-base font-medium text-gray-400 hover:bg-gray-700 hover:text-white"
                                            >
                                                {item.name}
                                            </Disclosure.Button>
                                        ))}
                                    </div>
                                </div>
                            </Disclosure.Panel>
                        </>
                    )}
                </Disclosure>

                <header className="bg-zluta shadow">
                    <div className="mx-auto max-w-7xl px-4 py-6 sm:px-6 lg:px-8">
                        <h1 className="text-3xl font-bold tracking-tight text-gray-900">
                            Úkoly
                        </h1>
                    </div>
                </header>
                <main>
                    {section === 1 &&
                        <div className=' w-96 h-96 mx-auto mt-7'>
                            <QrScanner
                                onDecode={(result) => akce(result)}
                                onError={(error) => console.log(error?.message)}
                            />
                            <p className='mt-3'>Naskenujte QR kód úkolu</p>
                        </div>
                    }
                    {section === 3 &&
                        <div className=' w-96 h-96 mx-auto mt-7'>
                            <button onClick={() => setSection(1)}>dsadasdas</button>
                        </div>
                    }
                    {section === 2 && <div>
                        <div className="flex justify-center mt-32 gap-10 mb-10">
                            <div className="md:max-w-md lg:col-span-2">
                                <span className="text-base text-center font-medium tracking-wide text-gray-300">
                                    Zadej skóre
                                </span>
                                <div className="flex flex-col mt-4 md:flex-row">
                                    <input
                                        placeholder="Skóre"
                                        required
                                        type="number"
                                        value={cass}
                                        onSubmit={() => setCas(0)}
                                        onChange={(e) => {
                                            setCas(e.target.value)
                                            console.log(e.target.value + "<-- skore / cas idk");
                                            
                                        }}
                                        className="flex-grow w-full h-12 px-4 mb-3 transition duration-200 bg-white border border-gray-300 rounded shadow-sm appearance-none md:mr-2 md:mb-0 focus:border-deep-purple-accent-400 focus:outline-none focus:shadow-outline"
                                    />
                                </div>
                                <p className="mt-4 text-sm text-gray-500">
                                    Zadejte skóre uživatele na stanovišti
                                </p>
                            </div>
                            <div className="flex flex-col">
                            <span className="text-base text-center font-medium tracking-wide text-gray-300">
                                    Vyberte hru
                                </span>
                            <div className="row">
                                <select
                                    id="country"
                                    name="country"
                                    onChange={(e) => {
                                        const selectedOptionId = e.target.value;
                                        console.log(selectedOptionId + "<-- picovina");
                                        handleSelectChange(selectedOptionId);
                                    }}
                                    className="block pl-2 mt-4 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6">
                                    <option value="1" id="1">
                                        --------
                                    </option>
                                    <option value="2" id="2">
                                        Fall guys
                                    </option>
                                    <option value="3" id="3">
                                        Tetris
                                    </option>
                                    <option value="4" id="4">
                                        Matyho hra
                                    </option>
                                    <option value="5" id="5">
                                        Únikovka
                                    </option>
                                    <option value="6" id="6">
                                        Karlova hra
                                    </option>
                                    <option value="7" id="7">
                                        Body
                                    </option>
                                </select>
                            </div>
                            </div>
                        </div>
                        <div className="flex justify-center">
                            <button
                            onClick={() => {submitScore(); handleShowToast(true)}}
                            className="inline-flex bg-zluta items-center justify-center h-12 px-6 font-medium tracking-wide text-white transition duration-200 rounded shadow-md bg-deep-purple-accent-400 hover:bg-deep-purple-accent-700 focus:shadow-outline focus:outline-none"
                        >
                            Odeslat
                        </button>
                        </div>
                    </div>}
                    
                    <div className="App">
                        <Toast
                            message="Skóre přidáno"
                            showToast={showToast}
                            onClose={() => setShowToast(false)}
                        />
                    </div>

                </main>
            </div>
        </>
    );
}
