import { Disclosure, Menu } from "@headlessui/react";
import { Bars3Icon, BellIcon, XMarkIcon } from "@heroicons/react/24/outline";
import React, { Fragment, useRef, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import logo from "../media/logo.png";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import { Api } from "../components/Knihovna";

const user = {
    name: "Tom Cook",
    email: "tom@example.com",
    imageUrl:
        "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
};

const navigation = [
    { name: "Admin", href: "/", current: false },
    { name: "Stats", href: "/stats", current: false },
    { name: "Úkoly", href: "/ukoly", current: false },
    { name: "Hledat lidi", href: "/hledat-lidi", current: true },
    { name: "QR kódy", href: "/qr-kody", current: false },
];


const userNavigation = [
    { name: "Your Profile", href: "#" },
    { name: "Settings", href: "#" },
    { name: "Tutorial", href: "/tutorial" },
    { name: 'Leaderboard', href: '#' },
    { name: "Sign out", href: "#" },
];

function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}

export default function Home() {
    const [open, setOpen] = useState(false);

    const [id, setId] = useState("");

    const [openC, setOpenC] = useState(false);
    const cancelButtonRefC = useRef(null);

    const [users, getUsers] = useState([]);
    const [name, setName] = useState("");
    const [nick, setNick] = useState("");
    const [skore, setSkore] = useState("");
    const [cas, setCas] = useState("");
    const [indexx, setIndexx] = useState("");
    const [selectOp, setSelectOp] = useState("1");

    const [searchTerm, setSearchTerm] = useState("");
    //const [searchResultsNick, setSearchResultsNick] = useState(users);
    //const [searchResultsId, setSearchResultsId] = useState(users);
    //const [searchResultsName, setSearchResultsName] = useState(users);

    //--------toast-------
    const [showToast, setShowToast] = useState(false);

    const handleShowToast = () => {
        setShowToast(true);

        // Hide the toast after 3 seconds
        setTimeout(() => {
            setShowToast(false);
        }, 3000);
    };

    function Toast({ message, showToast, onClose }) {
        const [visible, setVisible] = useState(false);

        useEffect(() => {
            if(users.length === 0)
            {
            Api.getUsers().then((res) => {
                getUsers(res);
                //console.log(users);
            })
        }
            if (showToast) {
                setVisible(true);

                // Auto-hide the toast after a few seconds (adjust as needed)
                const timeout = setTimeout(() => {
                    onClose();
                }, 1000);

                return () => clearTimeout(timeout);
            }
        }, [showToast, onClose]);

        return (
            <div
                className={`fixed top-5 right-5 bg-cervena bg-opacity-70 p-3 bg-blue-500 text-bila rounded-md ${
                    visible
                        ? "opacity-100 translate-y-0"
                        : "opacity-0 translate-y-full"
                } transform transition-transform transition-opacity ease-in-out duration-300`}
            >
                {message}
            </div>
        );
    }
    //-------------------

    const cancelButtonRef = useRef(null);

    function SetParams(i) {
        setOpen(true);
        setIndexx(users[i].id);

        console.log(users[i]);

        setNick(users[i].displayName);
        setId(users[i].id);
        setName(users[i].username);
        //setCas(userList[index].cas);
        setSkore(users[i].score);
    }

    // ------------hledani lidi ffs------------------------

    const handleSearch = () => {
    console.log(searchTerm)
      /*  let filteredResultsNick = users.filter((user) =>
        {
           return user.username.toLowerCase().includes(searchTerm.toLowerCase())
        }
        );
        let filteredResultsName = users.filter((user) =>
        {
            return user.displayName.toLowerCase().includes(searchTerm.toLowerCase())
        }
        );
        let filteredResultsId = users.filter((user) => user.id);*/

        //setSearchResultsNick(filteredResultsNick);
        //setSearchResultsName(filteredResultsName);
        //setSearchResultsId(filteredResultsId);

        let userFound = false;

        for (let i = 0; i < users.length; i++) 
        {

            if (users[i].displayName === searchTerm) {
                if (users[i].displayName === searchTerm) {
                 
                    SetParams(i);
                    userFound = true;
                    setOpen(true);
                } else {
                    console.log("neni");
                }
            } else if (users[i].username === searchTerm) {
                if (users[i].username === searchTerm) {
                   
                    SetParams(i);
                    userFound = true;
                    setOpen(true);
                } else {
                    console.log("neni");
                }
            } else if (users[i].id === searchTerm) {
                if (users[i].id === searchTerm) {
                
                    SetParams(i);
                    userFound = true;
                    setOpen(true);
                } else {
                    console.log("neni");
                }
            }
        }

        if (userFound === false) {
            handleShowToast(true);
        }

        //setSearchResultsNick(users);
        //setSearchResultsName(users);
        //setSearchResultsId(users);
        setSearchTerm("");
    };

    const handleOnSearch = (results) => {
        setSearchTerm(results);
    };

    const handleOnSelect = (results) => {
        setSearchTerm(results.username);
    };

    // --------------------------

    function handleSelectChange(selectOp) {

        let selectedOption = selectOp;
        console.log(selectedOption);
        console.log("zmenaaaa");

        switch (selectedOption) {
            case "1":
                console.log("Uživatel upraven");
                Api.updateUser(id, nick, skore)
                break;
            case "2":
                console.log("Uživatel Smazán");
                setOpenC(true);
                Api.deleteUser(id);
                break;
            default:
                console.log("default");
        }

        setSelectOp("1")
    }

    return (
        <>
            <div className="min-h-full">
                <Disclosure
                    as="nav"
                    className="bg-gray-800 bg-peknaCerna text-bila"
                >
                    {({ open }) => (
                        <>
                            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                                <div className="flex h-16 items-center justify-between">
                                    <div className="flex items-center">
                                        <div className="flex-shrink-0">
                                            <img
                                                className="h-10 w-13"
                                                src={logo}
                                                alt="Your Company"
                                            />
                                        </div>
                                        <div className="hidden md:block">
                                            <div className="ml-10 flex items-baseline space-x-4">
                                                {navigation.map((item) => (
                                                    <a
                                                        key={item.name}
                                                        href={item.href}
                                                        className={classNames(
                                                            item.current
                                                                ? "bg-gray-900 text-white"
                                                                : "text-gray-300 hover:bg-gray-700 hover:text-white",
                                                            "rounded-md px-3 py-2 text-sm font-medium"
                                                        )}
                                                        aria-current={
                                                            item.current
                                                                ? "page"
                                                                : undefined
                                                        }
                                                    >
                                                        {item.name}
                                                    </a>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="hidden md:block">
                                        <div className="ml-4 flex items-center md:ml-6">
                                            {/*  <button
                        type="button"
                        className="relative rounded-full bg-gray-800 p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                      >
                        <span className="absolute -inset-1.5" />
                        <span className="sr-only">View notifications</span>
                        <BellIcon className="h-6 w-6" aria-hidden="true" />
                      </button> */}

                                            {/* Profile dropdown */}
                                            <Menu
                                                as="div"
                                                className="relative ml-3"
                                            >
                                                <div>
                                                    <Menu.Button className="relative flex max-w-xs items-center rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                                                        <span className="absolute -inset-1.5" />
                                                        <span className="sr-only">
                                                            Open user menu
                                                        </span>
                                                        <img
                                                            className="h-8 w-8 rounded-full"
                                                            src={user.imageUrl}
                                                            alt=""
                                                        />
                                                    </Menu.Button>
                                                </div>
                                                <Transition
                                                    as={Fragment}
                                                    enter="transition ease-out duration-100"
                                                    enterFrom="transform opacity-0 scale-95"
                                                    enterTo="transform opacity-100 scale-100"
                                                    leave="transition ease-in duration-75"
                                                    leaveFrom="transform opacity-100 scale-100"
                                                    leaveTo="transform opacity-0 scale-95"
                                                >
                                                    <Menu.Items className="absolute bg-peknaCerna right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                        {userNavigation.map(
                                                            (item) => (
                                                                <Menu.Item
                                                                    key={
                                                                        item.name
                                                                    }
                                                                >
                                                                    {({
                                                                        active,
                                                                    }) => (
                                                                        <a
                                                                            href={
                                                                                item.href
                                                                            }
                                                                            className={classNames(
                                                                                active
                                                                                    ? "bg-zluta text-cerna"
                                                                                    : "",
                                                                                "block px-4 py-2 text-sm text-gray-700"
                                                                            )}
                                                                        >
                                                                            {
                                                                                item.name
                                                                            }
                                                                        </a>
                                                                    )}
                                                                </Menu.Item>
                                                            )
                                                        )}
                                                    </Menu.Items>
                                                </Transition>
                                            </Menu>
                                        </div>
                                    </div>
                                    <div className="-mr-2 flex md:hidden">
                                        {/* Mobile menu button */}
                                        <Disclosure.Button className="relative inline-flex items-center justify-center rounded-md bg-gray-800 p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                                            <span className="absolute -inset-0.5" />
                                            <span className="sr-only">
                                                Open main menu
                                            </span>
                                            {open ? (
                                                <XMarkIcon
                                                    className="block h-6 w-6"
                                                    aria-hidden="true"
                                                />
                                            ) : (
                                                <Bars3Icon
                                                    className="block h-6 w-6"
                                                    aria-hidden="true"
                                                />
                                            )}
                                        </Disclosure.Button>
                                    </div>
                                </div>
                            </div>

                            <Disclosure.Panel className="md:hidden">
                                <div className="space-y-1 px-2 pb-3 pt-2 sm:px-3">
                                    {navigation.map((item) => (
                                        <Disclosure.Button
                                            key={item.name}
                                            as="a"
                                            href={item.href}
                                            className={classNames(
                                                item.current
                                                    ? "bg-gray-900 text-white"
                                                    : "text-gray-300 hover:bg-gray-700 hover:text-white",
                                                "block rounded-md px-3 py-2 text-base font-medium"
                                            )}
                                            aria-current={
                                                item.current
                                                    ? "page"
                                                    : undefined
                                            }
                                        >
                                            {item.name}
                                        </Disclosure.Button>
                                    ))}
                                </div>
                                <div className="border-t border-gray-700 pb-3 pt-4">
                                    <div className="flex items-center px-5">
                                        <div className="flex-shrink-0">
                                            <img
                                                className="h-10 w-10 rounded-full"
                                                src={user.imageUrl}
                                                alt=""
                                            />
                                        </div>
                                        <div className="ml-3">
                                            <div className="text-base font-medium leading-none text-white">
                                                {user.name}
                                            </div>
                                            <div className="text-sm font-medium leading-none text-gray-400">
                                                {user.email}
                                            </div>
                                        </div>
                                        {
                                            <button
                                                type="button"
                                                className="relative ml-auto flex-shrink-0 rounded-full bg-gray-800 p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                                            >
                                                <span className="absolute -inset-1.5" />
                                                <span className="sr-only">
                                                    View notifications
                                                </span>
                                                <BellIcon
                                                    className="h-6 w-6"
                                                    aria-hidden="true"
                                                />
                                            </button>
                                        }
                                    </div>
                                    <div className="mt-3 space-y-1 px-2">
                                        {userNavigation.map((item) => (
                                            <Disclosure.Button
                                                key={item.name}
                                                as="a"
                                                href={item.href}
                                                className="block rounded-md px-3 py-2 text-base font-medium text-gray-400 hover:bg-gray-700 hover:text-white"
                                            >
                                                {item.name}
                                            </Disclosure.Button>
                                        ))}
                                    </div>
                                </div>
                            </Disclosure.Panel>
                        </>
                    )}
                </Disclosure>

                <header className="bg-zluta shadow">
                    <div className="mx-auto max-w-7xl px-4 py-6 sm:px-6 lg:px-8">
                        <h1 className="text-3xl font-bold tracking-tight text-gray-900">
                            Hledat lidi
                        </h1>
                    </div>
                </header>
                <main>
                    {/*className='w-75 ml-5 mr-5 mt-10 md:w-[500px] md:mx-auto flex'*/}
                    <div className="w-75 ml-5 mr-5 mt-10 md:w-[500px] md:mx-auto flex">
                        <div className="w-5 min-w-0 flex-auto">
                            <ReactSearchAutocomplete
                                items={users}
                                fuseOptions={{ keys: ["username", "displayName"] }} // Search on both fields
                                resultStringKeyName="displayName" // String to display in the results
                                showIcon={false}
                                onSearch={handleOnSearch}
                                onSelect={handleOnSelect}
                            />
                        </div>
                        <button
                            type="button"
                            className="flex-none bg-zluta ml-3 rounded-md px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-peknaCerna hover:text-bila hover:transition-all focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                            onClick={handleSearch}
                        >
                            Hledat
                        </button>
                    </div>

                    {/* component */}
                    <div className="flex flex-col">
                        <div className="overflow-x-auto sm:mx-0.5 lg:mx-0.5">
                            <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
                                <div className="overflow-hidden">
                                    <table className="min-w-full">
                                        <thead className="bg-white border-b">
                                            <tr>
                                                <th
                                                    scope="col"
                                                    className="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                                                >
                                                    #
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                                                >
                                                    Jméno
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                                                >
                                                    Nick
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                                                >
                                                    ID
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                                                >
                                                    Score
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {users.map((list, key) => (
                                                <tr className="bg-gray-100 border-b">
                                                    <td className="text-sm mx-10 mt-4 font-semibold leading-10 text-gray-900">
                                                        {key}
                                                    </td>
                                                     <td className="text-md text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                                        {list.username}
                                                    </td>                                                   
                                                    <td className="text-md text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                                        @{list.displayName}
                                                    </td>
                                                    <td className="text-xs text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                                        {list.id}
                                                    </td>
                                                    <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                                        {list.score}
                                                    </td>
                                                    <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                                        <button
                                                            onClick={() =>
                                                                SetParams(key)                                                              
                                                            }
                                                            className="bg-zluta text-peknaCerna hover:bg-blue-700 mt-1 text-white font-bold py-2 px-4 rounded hover:bg-peknaCerna hover:text-bila hover:transition-all"
                                                        >
                                                            Upravit
                                                        </button>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>


                    <Transition.Root show={open} as={Fragment}>
                        <Dialog
                            as="div"
                            className="relative z-10"
                            initialFocus={cancelButtonRef}
                            onClose={setOpen}
                        >
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0"
                                enterTo="opacity-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                            >
                                <div className="fixed inset-0 bg-peknaCerna bg-opacity-75 transition-opacity" />
                            </Transition.Child>

                            <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                                <div className="flex items-end justify-center p-4 text-center sm:items-center sm:p-0">
                                    <Transition.Child
                                        as={Fragment}
                                        enter="ease-out duration-300"
                                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                                        leave="ease-in duration-200"
                                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                    >
                                        <Dialog.Panel className="relative sm:mt-32 transform overflow-hidden rounded-lg bg-bila text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                                            <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                                                <div>
                                                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                                                        <div className="mt-2">
                                                            <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                                                                <div className="col-span-12">
                                                                    <label
                                                                        htmlFor="id"
                                                                        className="block text-sm font-medium leading-6 text-gray-900"
                                                                    >
                                                                        ID
                                                                        uživatele:
                                                                    </label>
                                                                    <div className="mt-2">
                                                                        <input
                                                                            type="text"
                                                                            name="id"
                                                                            id="id"
                                                                            value={
                                                                                id
                                                                            }
                                                                            disabled
                                                                            className="block w-full pl-2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                                        />
                                                                    </div>
                                                                </div>

                                                                <div className="col-span-12">
                                                                    <label
                                                                        htmlFor="name"
                                                                        className="block text-sm font-medium leading-6 text-gray-900"
                                                                    >
                                                                        Jméno
                                                                    </label>
                                                                    <div className="mt-2">
                                                                        <input
                                                                            type="text"
                                                                            name="name"
                                                                            id="name"
                                                                            value={name}
                                                                            disabled
                                                                            onChange={(e) => {setName(e.target.value);}}
                                                                            className="block w-full pl-2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                                        />
                                                                    </div>
                                                                </div>

                                                                <div className="col-span-12">
                                                                    <label
                                                                        htmlFor="nick"
                                                                        className="block text-sm font-medium leading-6 text-gray-900"
                                                                    >
                                                                        Nick
                                                                    </label>
                                                                    <div className="mt-2">
                                                                        <input
                                                                            id="nick"
                                                                            name="nick"
                                                                            type="text"
                                                                            value={nick}
                                                                            onChange={(e) => {setNick(e.target.value);}}
                                                                            className="block pl-2 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="sm:col-span-12">
                                                                        <label
                                                                            htmlFor="skore"
                                                                            className="block text-sm sm:w-48 font-medium leading-6 text-gray-900"
                                                                        >
                                                                            Počet
                                                                            bodů
                                                                        </label>
                                                                        <div className="mt-2">
                                                                            <input
                                                                                type="number"
                                                                                name="skore"
                                                                                id="skore"
                                                                                value={
                                                                                    skore
                                                                                }
                                                                                onChange={(e) => {
                                                                                    console.log(e.target);
                                                                                    setSkore(e.target.value);}}
                                                                                className="block pl-2 w-48 col-12 mb-3 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                                            />
                                                                        </div>
                                                                    </div>

                                                                    <div className="sm:col-span-12">
                                                                        <label
                                                                            htmlFor="cas"
                                                                            className="block text-sm font-medium leading-6 text-gray-900"
                                                                        >
                                                                            Čas
                                                                        </label>
                                                                        <div className="mt-2">
                                                                            <input
                                                                                type="number"
                                                                                name="cas"
                                                                                id="cas"
                                                                                value={
                                                                                    cas
                                                                                }
                                                                                onChange={(e) => {setCas(e.targe.value);}}
                                                                                className="block pl-2 w-48 rounded-md mb-3 border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="sm:col-span-12">
                                                                    <label
                                                                        htmlFor="country"
                                                                        className="block text-sm font-medium leading-6 text-gray-900"
                                                                    >
                                                                        Co
                                                                        chcete
                                                                        dělat?
                                                                    </label>
                                                                    <div className="mt-2 col-span-12">
                                                                        <select
                                                                            id="country"
                                                                            name="country"
                                                                            onChange={(e) => {
                                                                                
                                                                                setSelectOp(e.target.value);
                                                                                
                                                                            }}
                                                                            className="block pl-2 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                                                                        >
                                                                            <option value="1" id="1">
                                                                                Upravit uživatele
                                                                            </option>
                                                                            <option value="2" id="2">
                                                                                Smazat uživatele
                                                                            </option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                                                <button
                                                    type="button"
                                                    className="inline-flex w-full justify-center rounded-md bg-cervena text-bila px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                                                    onClick={() => {
                                                        setOpen(false);
                                                        handleSelectChange(selectOp)
                                                        console.log(selectOp + " <---- fdas")
                                                    }}
                                                >
                                                    Potvrdit
                                                </button>
                                                <button
                                                    type="button"
                                                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                                                    onClick={() =>
                                                        setOpen(false)
                                                    }
                                                    ref={cancelButtonRef}
                                                >
                                                    Zpět
                                                </button>
                                            </div>
                                        </Dialog.Panel>
                                    </Transition.Child>
                                </div>
                            </div>
                        </Dialog>
                    </Transition.Root>

                    <Transition.Root show={openC} as={Fragment}>
                        <Dialog
                            as="div"
                            className="relative z-10"
                            initialFocus={cancelButtonRefC}
                            onClose={setOpenC}
                        >
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0"
                                enterTo="opacity-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                            >
                                <div className="fixed inset-0 bg-peknaCerna bg-opacity-75 transition-opacity" />
                            </Transition.Child>

                            <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                                <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                                    <Transition.Child
                                        as={Fragment}
                                        enter="ease-out duration-300"
                                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                                        leave="ease-in duration-200"
                                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                    >
                                        <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-bila text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                                            <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                                                <div className="sm:flex sm:items-start">
                                                    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                                                        <ExclamationTriangleIcon
                                                            className="h-6 w-6 text-red-600"
                                                            aria-hidden="true"
                                                        />
                                                    </div>
                                                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                                                        <Dialog.Title
                                                            as="h3"
                                                            className="text-base font-semibold leading-6 text-gray-900"
                                                        >
                                                            U sure?
                                                        </Dialog.Title>
                                                        <div className="mt-2">
                                                            <p className="text-sm text-gray-500">
                                                                Opravdu chcete
                                                                smazat tohoto
                                                                uživatele?
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                                                <button
                                                    type="button"
                                                    className="inline-flex w-full justify-center bg-cervena text-bila rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                                                    onClick={() => {
                                                        setOpenC(false);
                                                        console.log(indexx)
                                                        Api.deleteUser(indexx);
                                                        }}>
                                                    Smaž ho
                                                </button>
                                                <button
                                                    type="button"
                                                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                                                    onClick={() =>
                                                        setOpenC(false)
                                                    }
                                                    ref={cancelButtonRefC}
                                                >
                                                    Ne
                                                </button>
                                            </div>
                                        </Dialog.Panel>
                                    </Transition.Child>
                                </div>
                            </div>
                        </Dialog>
                    </Transition.Root>

                    <div className="App">
                        <Toast
                            message="Uživatel nenalezen"
                            showToast={showToast}
                            onClose={() => setShowToast(false)}
                        />
                    </div>
                </main>
            </div>
        </>
    );
}
